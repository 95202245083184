import React from "react";
import Resourse from "../Resourse";

const res = Resourse;

class Platform extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="content-no-telegram">
                <p>Open on your mobile</p>
                <a href="https://t.me/brawlsboxesbot">
                    <img src={res.Other.Load("qrcode")} alt="@brawlsboxesbot"></img>
                </a>
                <a href="https://t.me/brawlsboxesbot">@brawlsboxesbot</a>
            </div>
        )
    }
}

export default Platform