import React from "react";

class NavButton extends React.Component{
    constructor(props){
        super(props)

        this.InputClick = this.InputClick.bind(this)
    }

    render(){
        return (
        <div className={this.props.actv} onClick={this.InputClick}  ref={this.props.nav}>
            <img src={this.props.src} alt="Icon" className="nav-button-icon"></img>
            <p className="nav-button-text">{this.props.text}</p>
        </div>)
    }

    InputClick(){
        this.props.onClick(this);
    }
}

export default NavButton