import React from "react";
import Resourse from "../Resourse";
import { motion, useAnimation, useInView } from 'framer-motion';

const res = Resourse;

class Leaderboard extends React.Component {
    constructor(props) {
        super(props);

        this.league = {
            0: {
                color_1: "#F0622A",
                color_2: "#F69974"
            },
            1: {
                color_1: "#576CC4",
                color_2: "#8B9AD7"
            },
            2: {
                color_1: "#FFB41C",
                color_2: "#FFCE6B"
            },
            3: {
                color_1: "#3A6DE1",
                color_2: "#6EC0FC"
            },
            4: {
                color_1: "#8F19D7",
                color_2: "#CB72F8"
            },
            5: {
                color_1: "#FF1C1C",
                color_2: "#FF6B6B"
            },
            6: {
                color_1: "#B22F28",
                color_2: "#EE7137"
            }
        };

        this.state = {
            leagueIndex: this.props.league,
            corrBoxes: 732421,
            needBoxes: 1000000,
            topUsers: [
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                },
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                },
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                },
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                },
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                },
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                },
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                },
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                },
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                },
                {
                    avatar: 32,
                    name: "rst1k",
                    boxes: 0,
                    me: true
                },
                {
                    avatar: 12,
                    name: "",
                    boxes: 0,
                    me: false
                }
            ],
            leagueTopDeskHeight: 0
        };

        this.leagueTopDeskRef = React.createRef();
    }

    componentDidMount() {
        this.updateLeagueTopDeskHeight();
        window.addEventListener('resize', this.updateLeagueTopDeskHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLeagueTopDeskHeight);
    }

    updateLeagueTopDeskHeight = () => {
        if (this.leagueTopDeskRef.current && this.props.nav.current) {
            let lead_temp = this.leagueTopDeskRef.current.getBoundingClientRect();
            let nav_temp = this.props.nav.current.getBoundingClientRect();

            const League_Top_Desk_Height = Math.abs((nav_temp.top - lead_temp.bottom)-30);
            this.setState({ leagueTopDeskHeight: League_Top_Desk_Height });
        }
    };

    loadColor() {
        return this.league[this.state.leagueIndex] || null;
    }

    formatNumber(num) {
        if (num >= 1_000_000) {
            return (num / 1_000_000).toFixed(1) + 'M';
        } else if (num >= 1_000) {
            return (num / 1_000).toFixed(1) + 'K';
        } else {
            return num.toString();
        }
    }

    calculatePercentage() {
        if (this.state.needBoxes <= 0) {
            throw new Error('Bad Need Box');
        }
        const percentage = Math.round((this.state.corrBoxes / this.state.needBoxes) * 100);
        return Math.max(0, Math.min(99, percentage));
    }

    showSlide = (index) => {
        const leagueCount = 7;
        if (index >= leagueCount) {
            this.setState({ leagueIndex: 0 });
        } else if (index < 0) {
            this.setState({ leagueIndex: leagueCount - 1 });
        } else {
            this.setState({ leagueIndex: index });
        }
    };

    nextSlide = () => {
        this.showSlide(this.state.leagueIndex + 1);
        this.setState({corrBoxes: this.state.corrBoxes + 20})
    };

    prevSlide = () => {
        this.showSlide(this.state.leagueIndex - 1);
        this.setState({corrBoxes: this.state.corrBoxes - 20})
    };

    render() {
        const colors = this.loadColor();
        const gradientVariants = {
            initial: {
                background: `linear-gradient(transparent, #0000005c), radial-gradient(circle, ${colors.color_2}, ${colors.color_1})`,
            },
            animate: {
                background: `linear-gradient(transparent, #0000005c), radial-gradient(circle, ${colors.color_2}, ${colors.color_1})`,
                transition: { duration: 0.5, ease: "easeInOut" }
            }
        };

        const { leagueIndex } = this.state;
        const offset = -leagueIndex * 100;

        const percentage = this.calculatePercentage();

        const Parentdiv = {
            height: 15,
            width: '90%',
            backgroundColor: '#619bed',
            borderRadius: 40,
            margin: '-79px auto 15px'
        };

        const CurrentEnegry = {
            fontSize: '100%',
            alignSelf: "flex-end",
            color: "#fff"
        }

        var children = [];

        for (let player of this.state.topUsers) {
            let Avatar = {
                backgroundImage: `url(${res.Avatars.Load(player.avatar)})`,
                backgroundSize: 'contain',
                backgroundPosition: 'flex-start',
                backgroundRepeat: 'no-repeat'
            }
            children.push(
                <div className={player.me ? "league-top-player-me" : "league-top-player"}>
                    <div className="league-top-player-avatar" style={Avatar}></div>
                    <div className="league-top-player-stats">
                        <div className="league-top-player-stats-name">
                            <p>{player.name}</p>
                        </div>
                        <div className="league-top-player-stats-boxes">
                            <div class="league-top-player-stats-boxes-icon"></div>
                            <p>{player.boxes}</p>
                        </div>
                    </div>
                    <div className="league-top-player-num">
                        <p>{this.state.topUsers.indexOf(player) + 1}</p>
                    </div>
                </div>);
        }

        return (
            <motion.div
                className="leaderboard-div"
                initial="initial"
                animate="animate"
                variants={gradientVariants}
                style={{ width: '100%', height: '100%' }}
            >
                {
                    //LEAGUE SLIDER START
                }
                <div className="league-slider-container">
                    <div className="league-slider" style={{ transform: `translateX(${offset}%)` }}>
                        <div className="league-slide" id="bronze">
                            <img src={res.League.Load(0)} alt="Bronze League" />
                            <p>Bronze</p>
                        </div>
                        <div className="league-slide" id="silver">
                            <img src={res.League.Load(1)} alt="Silver League" />
                            <p>Silver</p>
                        </div>
                        <div className="league-slide" id="gold">
                            <img src={res.League.Load(2)} alt="Gold League" />
                            <p>Gold</p>
                        </div>
                        <div className="league-slide" id="diamond">
                            <img src={res.League.Load(3)} alt="Platinum League" />
                            <p>Diamond</p>
                        </div>
                        <div className="league-slide" id="mythic">
                            <img src={res.League.Load(4)} alt="Diamond League" />
                            <p>Mythic</p>
                        </div>
                        <div className="league-slide" id="legendary">
                            <img src={res.League.Load(5)} alt="Legendary League" />
                            <p>Legendary</p>
                        </div>
                        <div className="league-slide" id="mastery">
                            <img src={res.League.Load(6)} alt="Titan League" />
                            <p>Masters</p>
                        </div>
                    </div>
                    <button className="league-btn-prev" onClick={this.prevSlide}>
                        <img className="league-btn-image" src={res.Icons.Load("left")} alt="Previous" />
                    </button>
                    <button className="league-btn-next" onClick={this.nextSlide}>
                        <img className="league-btn-image" src={res.Icons.Load("right")} alt="Next" />
                    </button>
                </div>
                {
                    //LEAGUE SLIDER END
                }
                <div className="league-stats">
                    <span style={CurrentEnegry}>{this.formatNumber(this.state.corrBoxes)}</span><span style={CurrentEnegry}>/{this.formatNumber(this.state.needBoxes)}</span>
                </div>
                <ProgressBarContent 
                    percentage={percentage} 
                    bgcolor="#80DDFF" 
                    Parentdiv={Parentdiv} 
                />
                <div className="league-top-desk" ref={this.leagueTopDeskRef} style={{ height: this.state.leagueTopDeskHeight }}>
                    {children}
                </div>

            </motion.div>
        );
    }
}

const ProgressBarContent = ({ percentage, bgcolor, Parentdiv }) => {
    const controls = useAnimation();
    const ref = React.useRef(null);
    const inView = useInView(ref, { once: true });

    React.useEffect(() => {
        if (inView) {
            controls.start({
                width: `${percentage}%`,
                transition: { duration: 1 }
            });
        }
    }, [inView, controls, percentage]);

    const Childdiv = {
        height: '80%',
        width: '0%',
        backgroundColor: bgcolor,
        borderRadius: 40,
        border: "2px solid #619bed",
        textAlign: 'right'
    };

    return (
        <div className="progress-main" style={Parentdiv} ref={ref}>
            <motion.div 
                style={Childdiv}
                animate={controls}
            >
                <span></span>
            </motion.div>
        </div>
    );
};

export default Leaderboard;
