import React from 'react'
import Resourse from "../Resourse";

const res = Resourse;

class LeagueInfo extends React.Component{
    constructor(props){
        super(props)

        this.InputClick = this.InputClick.bind(this)
    }

    getLabel(){
        var league = this.props.league
        if(league === 0){
            return "Bronze"
        }else if(league === 1){
            return "Silver"
        }else if(league === 2){
            return "Gold"
        }else if(league === 3){
            return "Diamond"
        }else if(league === 4){
            return "Mythic"
        }else if(league === 5){
            return "Legendary"
        }else if(league === 6){
            return "Masters"
        }
    }

    render(){
        return(
            <>
            <div className="league-top">
                <div className="league-top-icon-box">
                    <img src={this.props.box} alt="Icon" className="league-top-icon"></img>
                </div>
                <div className="league-top-rank-box">
                    <span className="league-top-rank">{this.props.boxes}</span>
                </div>
            </div>
            <div className="league-bottom">
                <div className="league-bottom-icon-box" onClick={this.InputClick}>
                    <img src={res.League.Load(this.props.league)} alt="League" className="league-bottom-icon" onClick={this.InputClick}></img>
                </div>
                <div className="league-bottom-label" onClick={this.InputClick}>
                    <span>{this.getLabel()}</span>
                </div>
            </div>
            </>
        )
    }

    InputClick(){
        this.props.onClick(this);
    }
}

export default LeagueInfo