import React from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

class ProgressBar extends React.Component {
    calculatePercentage() {
        if (this.props.max <= 0) {
            throw new Error('Bad Max Enegry');
        }
        const percentage = Math.round((this.props.corr / this.props.max) * 100);
        return Math.max(0, Math.min(99, percentage));
    }

    render() {
        const percentage = this.calculatePercentage();

        const Parentdiv = {
            height: this.props.height,
            width: this.props.width,
            backgroundColor: '#619bed',
            borderRadius: 40,
            margin: this.props.margin
        };

        return (
            <ProgressBarContent 
                percentage={percentage} 
                bgcolor={this.props.bgcolor} 
                Parentdiv={Parentdiv} 
            />
        );
    }
}

const ProgressBarContent = ({ percentage, bgcolor, Parentdiv }) => {
    const controls = useAnimation();
    const ref = React.useRef(null);
    const inView = useInView(ref, { once: true });

    React.useEffect(() => {
        if (inView) {
            controls.start({
                width: `${percentage}%`,
                transition: { duration: 1 }
            });
        }
    }, [inView, controls, percentage]);

    const Childdiv = {
        height: '80%',
        width: '0%',
        backgroundColor: bgcolor,
        borderRadius: 40,
        border: "2px solid #619bed",
        textAlign: 'right'
    };

    return (
        <div className="progress-main" style={Parentdiv} ref={ref}>
            <motion.div 
                style={Childdiv}
                animate={controls}
            >
                <span></span>
            </motion.div>
        </div>
    );
};

export default ProgressBar;
