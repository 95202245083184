import React from 'react'

class MainBox extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='main-box'>
            </div>  
        )
    }
}

export default MainBox