import React from 'react'

class EnergyStats extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        const MaxEnegry = {
            fontSize: '140%',
            alignSelf: "flex-end",
            color: "#fff"
        }

        const CurrentEnegry = {
            fontSize: '110%',
            alignSelf: "flex-end",
            color: "#fff"
        }

        return (
        <div className="energy-stats">
            <div className="energy-icon"></div>
            <span style={MaxEnegry}>{this.props.corrEng}</span><span style={CurrentEnegry}>/{this.props.maxEng}</span>
        </div>
        )
    }
}

export default EnergyStats