import axios from 'axios';

class Network {

  static M = {
    GET: 'GET',
    POST: 'POST'
  };

  static baseURL = "https://brawlbox.fun/";

  static E = {
    AUTH_TG: "api/auth/telegram",
    AUTH_REFRESH:"api/auth/telegram/refresh",
    OHD:"api/data/own/home"
  };

  static async request(endPoint, method = this.M.GET, body = null, headers = {}, onTimeout = null, timeout = 5000) {
    try {
      const source = axios.CancelToken.source();
      let url = this.baseURL + endPoint;

      await this.checkToken(endPoint);
      
      if ([this.E.AUTH_REFRESH, this.E.AUTH_TG].indexOf(endPoint) === -1){
        headers = {
          Authorization : localStorage.getItem('token')
        }
      }

      if (method === this.M.GET && body) {
        const params = new URLSearchParams(body).toString();
        url += `?${params}`;
      }

      const fetchTimeout = setTimeout(() => {
        source.cancel();
        if (onTimeout) onTimeout();
      }, timeout);

      const response = await axios({
        url,
        method,
        headers: {
          'Content-Type': 'application/json',
          ...headers
        },
        data: method === this.M.POST ? body : null,
        cancelToken: source.token
      });

      clearTimeout(fetchTimeout);

      return {
        json: response.data,
        code: response.status
      };
    } catch (error) {
      if (error.response) {
        return {
          json: error.response.data,
          code: error.response.status
        };
      } else {
        return {
          json: null,
          code: null
        };
      }
    }
  }

  static async checkToken(endPoint) {
    if ([this.E.AUTH_REFRESH, this.E.AUTH_TG].indexOf(endPoint) === -1) {
      const token = localStorage.getItem('token');
      if (token) {
        const tokenExpireIn = localStorage.getItem('tokenExpireIn');
        const expireTime = new Date(tokenExpireIn);
        const now = new Date();
        const nowFormat = new Date(now.getTime() + (1 - now.getTimezoneOffset() / 60) * 3600 * 1000);
  
        if (nowFormat > expireTime) {
          try {
            const resp = await this.request(this.E.AUTH_REFRESH, this.M.POST, {}, {
              Authorization: localStorage.getItem('refreshToken')
            });
            
            if (resp.code === 200) {
              localStorage.setItem('token', resp.json.token);
              localStorage.setItem('refreshToken', resp.json.refreshToken);
              localStorage.setItem('tokenExpireIn', resp.json.expireIn);
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  }
}  

export default Network;
