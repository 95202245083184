//icons
import other_pic from "./assets/images/icons/other.png";
import task_pic from "./assets/images/icons/task.png";
import box_pic from "./assets/images/icons/box.png";
import shop_pic from "./assets/images/icons/shop.png";
import profile_pic from "./assets/images/icons/profile.png";
import warning from "./assets/images/icons/warning.png"
import left from "./assets/images/icons/left.png"
import right from "./assets/images/icons/rigth.png"
import down from "./assets/images/icons/down.png"
import gems from "./assets/images/icons/gems.png"
import gem from "./assets/images/icons/gem.png"
import starpower from "./assets/images/icons/starpower.png"
import coins from "./assets/images/icons/coins.png"
import coin from "./assets/images/icons/coin.png"
import gems_h from "./assets/images/icons/gems-hres.png"
import brawlers from "./assets/images/icons/brawlers.png"
import powerpoints from "./assets/images/icons/powerpoints.png"
import powerpoint from "./assets/images/icons/powerpoint.png"
import box_h from "./assets/images/icons/box-hres.png";
import calendar from "./assets/images/icons/calendar.png";
import gadget from "./assets/images/icons/gadget.png";
import no_gadget from "./assets/images/icons/no-gadget.png";
import no_starpower from "./assets/images/icons/no-starpower.png";
import no_brawler from "./assets/images/icons/no-brawler.png";
import no_7lvl from "./assets/images/icons/no-7lvl.png";
import no_9lvl from "./assets/images/icons/no-9lvl.png";
import power_lvl from "./assets/images/icons/power-lvl.png";
import power_lvl_upgrade from "./assets/images/icons/power-lvl-upgrade.png";
//brawlers
import shelly from "./assets/images/brawlers/shelly.png"
//league
import bronze_rank from "./assets/images/league/bronze.png"
import silver_rank from "./assets/images/league/silver.png"
import gold_rank from "./assets/images/league/gold.png"
import diamond_rank from "./assets/images/league/diamond.png"
import mythic_rank from "./assets/images/league/mythic.png"
import legendary_rank from "./assets/images/league/legendary.png"
import masters_rank from "./assets/images/league/masters.png"
//avatars
import ava_default from "./assets/images/avatars/default.png"
import ava_dev from "./assets/images/avatars/dev.png"
import ava_parther from "./assets/images/avatars/partner.png"
//avatars-league
import ava_league_bronze from "./assets/images/avatars/league_bronze.png"
import ava_league_silver from "./assets/images/avatars/league_silver.png"
import ava_league_gold from "./assets/images/avatars/league_gold.png"
import ava_league_diamond from "./assets/images/avatars/league_diamond.png"
import ava_league_mythic from "./assets/images/avatars/league_mythic.png"
import ava_league_legendary from "./assets/images/avatars/league_legendary.png"
import ava_league_masters from "./assets/images/avatars/league_masters.png"
//avatars-id
import ava_1 from "./assets/images/avatars/1.png"
import ava_2 from "./assets/images/avatars/2.png"
import ava_3 from "./assets/images/avatars/3.png"
import ava_4 from "./assets/images/avatars/4.png"
import ava_5 from "./assets/images/avatars/5.png"
import ava_6 from "./assets/images/avatars/6.png"
import ava_7 from "./assets/images/avatars/7.png"
import ava_8 from "./assets/images/avatars/8.png"
import ava_9 from "./assets/images/avatars/9.png"
import ava_10 from "./assets/images/avatars/10.png"
import ava_11 from "./assets/images/avatars/11.png"
import ava_12 from "./assets/images/avatars/12.png"
import ava_13 from "./assets/images/avatars/13.png"
import ava_14 from "./assets/images/avatars/14.png"
import ava_15 from "./assets/images/avatars/15.png"
import ava_16 from "./assets/images/avatars/16.png"
import ava_17 from "./assets/images/avatars/17.png"
import ava_18 from "./assets/images/avatars/18.png"
import ava_19 from "./assets/images/avatars/19.png"
import ava_20 from "./assets/images/avatars/20.png"
import ava_21 from "./assets/images/avatars/21.png"
import ava_22 from "./assets/images/avatars/22.png"
import ava_23 from "./assets/images/avatars/23.png"
//other
import small_box from "./assets/images/other/small_box.png"
import qrcode from "./assets/images/other/qrcode.png"
//animation - drop small box
import drop_small_box_0 from "./assets/animation/drop_small_box/0.png"
import drop_small_box_1 from "./assets/animation/drop_small_box/1.png"
import drop_small_box_2 from "./assets/animation/drop_small_box/2.png"
import drop_small_box_3 from "./assets/animation/drop_small_box/3.png"
//animation - jump small box
import jump_small_box_0 from "./assets/animation/jump_small_box/0.png"
import jump_small_box_1 from "./assets/animation/jump_small_box/1.png"
import jump_small_box_2 from "./assets/animation/jump_small_box/2.png"
import jump_small_box_3 from "./assets/animation/jump_small_box/3.png"
import jump_small_box_4 from "./assets/animation/jump_small_box/4.png"
import jump_small_box_5 from "./assets/animation/jump_small_box/5.png"
import jump_small_box_6 from "./assets/animation/jump_small_box/6.png"
import jump_small_box_7 from "./assets/animation/jump_small_box/7.png"
//animation - open small box
import open_small_box_0 from "./assets/animation/open_small_box/0.png"
import open_small_box_1 from "./assets/animation/open_small_box/1.png"
import open_small_box_2 from "./assets/animation/open_small_box/2.png"
import open_small_box_3 from "./assets/animation/open_small_box/3.png"
import open_small_box_4 from "./assets/animation/open_small_box/4.png"
import open_small_box_5 from "./assets/animation/open_small_box/5.png"
import open_small_box_6 from "./assets/animation/open_small_box/6.png"
import open_small_box_7 from "./assets/animation/open_small_box/7.png"
import open_small_box_8 from "./assets/animation/open_small_box/8.png"

class Resource {

    static Levels = class {
        constructor(){
            this.levels = {
                1 : {
                    coins: 1,
                    powerpoints: 1
                },
                2: {
                    coins: 10,
                    powerpoints: 10
                },
                3: {
                    coins: 20,
                    powerpoints: 20
                },
                4: {
                    coins: 30,
                    powerpoints: 30
                },
                5: {
                    coins: 40,
                    powerpoints: 40
                },
                6: {
                    coins: 50,
                    powerpoints: 50
                },
                7: {
                    coins: 60,
                    powerpoints: 60
                },
                8: {
                    coins: 70,
                    powerpoints: 70
                },
                9: {
                    coins: 80,
                    powerpoints: 80
                },
                10: {
                    coins: 90,
                    powerpoints: 90
                },
                11: {
                    coins: 100,
                    powerpoints: 100
                }
            }
        }

        static Load(id) {
            const LevelsInstance = new Resource.Levels();
            return LevelsInstance.levels[id] || null;
        }
    }

    static Avatars = class {
        constructor(){
            this.avatars = {
                0: ava_default,
                1: ava_1,
                2: ava_2,
                3: ava_3,
                4: ava_4,
                5: ava_5,
                6: ava_6,
                7: ava_7,
                8: ava_8,
                9: ava_9,
                10: ava_10,
                11: ava_11,
                12: ava_12,
                13: ava_13,
                14: ava_14,
                15: ava_15,
                16: ava_16,
                17: ava_17,
                18: ava_18,
                19: ava_19,
                20: ava_20,
                21: ava_21,
                22: ava_22,
                23: ava_23,
                24: ava_league_bronze,
                25: ava_league_silver,
                26: ava_league_gold,
                27: ava_league_diamond,
                28: ava_league_mythic,
                29: ava_league_legendary,
                30: ava_league_masters,
                31: ava_dev,
                32: ava_parther,
            }
        }

        static Load(id) {
            const AvatarsInstance = new Resource.Avatars();
            return AvatarsInstance.avatars[id] || null;
        }
    }

    static Brawlers = class{
        constructor(){
            this.brawlers = {
                0: {
                    icon:shelly,
                    rarity:0,
                    backround:"#94D7F4",
                    tmpName:"SHELLY"
                }
            }
        }

        static Load(id) {
            const brawlerInstance = new Resource.Brawlers();
            return brawlerInstance.brawlers[id] || null;
        }
    }

    static Icons = class{
        constructor(){
            this.icons = {
                "other": other_pic,
                "task": task_pic,
                "box": box_pic,
                "shop": shop_pic,
                "profile": profile_pic,
                "warning": warning,
                "left": left,
                "right": right,
                "down": down,
                "gems": gems,
                "gems-hres": gems_h,
                "gem": gem,
                "starpower": starpower,
                "coins":coins,
                "brawlers": brawlers,
                "powerpoints": powerpoints,
                "box-hres":box_h,
                "calendar":calendar,
                "no-gadget": no_gadget,
                "power-lvl": power_lvl,
                "coin":coin,
                "powerpoint": powerpoint,
                "gadget":gadget,
                "no-7lvl": no_7lvl,
                "no-9lvl":no_9lvl,
                "no-brawler":no_brawler,
                "no-starpower":no_starpower,
                "power-lvl-upgrade":power_lvl_upgrade
            }
        }

        static Load(id) {
            const iconsInstance = new Resource.Icons();
            return iconsInstance.icons[id] || null;
        }
    }

    static League = class{
        constructor(){
            this.ranks = {
                0: bronze_rank,
                1: silver_rank,
                2: gold_rank,
                3: diamond_rank,
                4: mythic_rank,
                5: legendary_rank,
                6: masters_rank
            }
        }

        static Load(id) {
            const ranksInstance = new Resource.League();
            return ranksInstance.ranks[id] || null;
        }
    }

    static Other = class{
        constructor(){
            this.other = {
                "small_box": small_box,
                "qrcode": qrcode
            }
        }

        static Load(id) {
            const otherInstance = new Resource.Other();
            return otherInstance.other[id] || null;
        }
    }

    static Animation =class{
        constructor(){
            this.animations = {
                "drop_small_box": [
                    drop_small_box_0,
                    drop_small_box_1,
                    drop_small_box_2,
                    drop_small_box_3
                ],
                "jump_small_box": [
                    jump_small_box_0,
                    jump_small_box_1,
                    jump_small_box_2,
                    jump_small_box_3,
                    jump_small_box_4,
                    jump_small_box_5,
                    jump_small_box_6,
                    jump_small_box_7
                ],
                "open_small_box": [
                    open_small_box_0,
                    open_small_box_1,
                    open_small_box_2,
                    open_small_box_3,
                    open_small_box_4,
                    open_small_box_5,
                    open_small_box_6,
                    open_small_box_7,
                    open_small_box_8
                ]
            }
        }

        static Load(id) {
            const animationInstance = new Resource.Animation();
            return animationInstance.animations[id] || null;
        }
    }
}

export default Resource;
