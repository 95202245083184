import React from "react";
import Resourse from "../Resourse";

const res = Resourse;

class NoResponse extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="server-no-response">
                <img src={res.Icons.Load("warning")} alt="Warning"></img>
                <p>Сервер не отвечает</p>
            </div>
        )
    }
}

export default NoResponse