import React from "react";
import { motion } from "framer-motion";

class SwitchButton extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            setIsOn: false,
            isOn: false
        }
    }

    toggleSwitch = () => {
        this.setIsOn(!this.isOn);
    };

    render(){
        return (
            <div className="switch-btn">
                     <div className="swith-box-on"></div>
                     <div className="swith-box-on"></div>
            </div>
        )
    }
}

export default SwitchButton;