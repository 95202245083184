import React from "react";
import Resourse from "../Resourse";
import ProgressBar from "../components/ProgressBar";

const res = Resourse;

class Task extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'task',
            TaskQuest: [
                {
                    title: "",
                    myProgress: 0,
                    needProgress: 100,
                    rewardType: 0,
                    rewardAmound: 1
                }
            ],
            TaskDeskHeight: 0,
            ReferalDeskHeight: 0
        };

        this.TaskDeskRef = React.createRef();
        this.ReferalDeskRef = React.createRef();
    }

    componentDidMount() {
        this.updateTaskpDeskHeight();
        window.addEventListener('resize', this.updateTaskpDeskHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateTaskpDeskHeight);
    }

    updateTaskpDeskHeight = () => {
        if (this.TaskDeskRef.current && this.props.nav.current) {
            let lead_temp = this.TaskDeskRef.current.getBoundingClientRect();
            let nav_temp = this.props.nav.current.getBoundingClientRect();

            const TaskDeskHeight = Math.abs((nav_temp.top - lead_temp.bottom) - 30);
            this.setState({ TaskDeskHeight });
        }
        if (this.state.activeTab === 'ref' && this.ReferalDeskRef.current && this.props.nav.current) {
            let lead_temp = this.ReferalDeskRef.current.getBoundingClientRect();
            let nav_temp = this.props.nav.current.getBoundingClientRect();

            const ReferalDeskHeight = Math.abs((nav_temp.top - lead_temp.bottom) - 30);
            this.setState({ ReferalDeskHeight });
        }
    };

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab }, () => {
            if (tab === 'ref' && this.state.ReferalDeskHeight === 0) {
                this.updateTaskpDeskHeight();
            }
        });
    }

    render() {
        const { activeTab } = this.state;

        const Childs = (
            <div className="task-quest" style={{border: `3px solid #A8FFFF`}}>
                <div className="task-content">
                    <div className="task-info">
                        <h2>PENIs BOBRA</h2>
                        <p>0/1</p>
                    </div>
                    <div className="task-icon" style={{backgroundImage:`url(${res.Icons.Load("gems")})`}}>
                        <p>x100</p>
                    </div>
                </div>
                <div className="task-progress">
                    <ProgressBar
                        bgcolor="#80DDFF"
                        max={100}
                        corr={100}
                        height={17}
                        width='93%'
                        margin='0px 0px 0px 10px'
                    />
                </div>
            </div>
        );

        const RefTask = [] 
        RefTask.push(
            <div className="task-quest-ref" style={true ? {border: `3px solid rgb(237, 230, 146)`} : {}}>
                <div className="task-content">
                    <div className="task-info-ref">
                        <h2>INVITE 50 FRIENDS</h2>
                        <p style={true ? {color: `rgb(237, 230, 146)`, marginTop:`0px`,marginBottom:`0px`} : {}}>50/50</p>
                    </div>
                    <div className="task-icon" style={{backgroundImage:`url(${res.Avatars.Load(12)})`}}>
                    </div>
                </div>
                <div className="task-progress">
                    <ProgressBar
                        bgcolor="#EDE692"
                        max={100}
                        corr={100}
                        height={17}
                        width='93%'
                        margin='0px 0px 0px 10px'
                    />
                </div>
            </div>
        );

        RefTask.push(
            <div className="task-quest-ref">
                <div className="task-content">
                    <div className="task-info-ref">
                        <h2>INVITE 50 FRIENDS</h2>
                        <p style={{marginTop:`0px`,marginBottom:`0px`}}>10/50</p>
                    </div>
                    <div className="task-icon" style={{backgroundImage:`url(${res.Avatars.Load(10)})`}}>
                    </div>
                </div>
                <div className="task-progress">
                    <ProgressBar
                        bgcolor="#80DDFF"
                        max={50}
                        corr={10}
                        height={17}
                        width='93%'
                        margin='0px 0px 0px 10px'
                    />
                </div>
            </div>
        );

        const Friends = [];

        let Avatar = {
            backgroundImage: `url(${res.Avatars.Load(12)})`,
            backgroundSize: 'contain',
            backgroundPosition: 'flex-start',
            backgroundRepeat: 'no-repeat'
        }
        Friends.push(
            <div className={"referal-friend"}>
                <div className="league-top-player-avatar" style={Avatar}></div>
                <div className="league-top-player-stats">
                    <div className="league-top-player-stats-name">
                        <p>РНш</p>
                    </div>
                    <div className="league-top-player-stats-boxes">
                        <div class="league-top-player-stats-boxes-icon"></div>
                        <p>1</p>
                    </div>
                </div>
                <div className="league-top-player-num">
                </div>
            </div>);

        Friends.push(
            <div className={"referal-friend"}>
                <div className="league-top-player-avatar" style={Avatar}></div>
                <div className="league-top-player-stats">
                    <div className="league-top-player-stats-name">
                        <p>РНш</p>
                    </div>
                    <div className="league-top-player-stats-boxes">
                        <div class="league-top-player-stats-boxes-icon"></div>
                        <p>1</p>
                    </div>
                </div>
                <div className="league-top-player-num">
                </div>
            </div>);

        return (
            <div className="task-screen">
                <div className="choose-task-tab">
                    <div
                        className={`choose-tab ${activeTab === 'task' ? 'active' : ''}`}
                        onClick={() => this.setActiveTab('task')}
                    >
                        <h1>TASK</h1>
                    </div>
                    <div
                        className={`choose-tab ${activeTab === 'ref' ? 'active' : ''}`}
                        onClick={() => this.setActiveTab('ref')}
                    >
                        <h1>REF</h1>
                    </div>
                </div>
                {activeTab === "task" && (
                    <div className="task-scroll" ref={this.TaskDeskRef} style={{ height: this.state.TaskDeskHeight }}>
                        {Childs}
                    </div>
                )}
                {activeTab === "ref" && (
                    <div className="ref-scroll" ref={this.ReferalDeskRef} style={{ height: this.state.ReferalDeskHeight }}>
                        <div className="ref-info">
                            <h1>28 Referals</h1>
                            <p>+28 000 GEMS</p>
                        </div>
                        <div className="ref-invite">
                            <h2>INVITE FRIENDS</h2>
                            <div className="ref-invite-share"><p>SHARE</p></div>
                            <div className="ref-invite-copy"><p>COPY</p></div>
                        </div>
                        <div className="ref-tasks">
                                {RefTask}
                        </div>
                        <div className="ref-friends-label">
                            <h2>YOUR FRIENDS:</h2>
                        </div>
                        <div className="ref-friends">
                            {Friends}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Task;
