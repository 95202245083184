import React from "react";
import Resourse from "../Resourse";
import { motion, useAnimation } from 'framer-motion';

const res = Resourse;

class OpenBox extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            currentAnimation: 'first', // 'first', 'looping', or 'third'
            isLooping: false,
          };
      
          this.animations = {
            first: res.Animation.Load("drop_small_box"), // Замените на ваши фреймы
            looping: res.Animation.Load("jump_small_box"), // Замените на ваши фреймы
            third: res.Animation.Load("open_small_box"), // Замените на ваши фреймы
          };
      
          this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({ currentAnimation: 'third' });
      }
    

    render(){
        const { currentAnimation, isLooping } = this.state;

        const variants = {
        first: {
            opacity: 1,
            transition: { duration: 1 },
        },
        looping: {
            opacity: 1,
            transition: { duration: 1 },
        },
        third: {
            opacity: 1,
            transition: { duration: 1 },
        },
        };

        const animationImages = this.animations[currentAnimation];

        return(
            <div className="game">
                <motion.div
                    className="sprite-container"
                    onClick={this.handleClick}
                    animate={currentAnimation}
                    variants={variants}
                    style={{
                    width: '100px',
                    height: '100px',
                    backgroundImage: `url(${animationImages[0]})`,
                    backgroundSize: 'cover',
                    }}
                    transition={{
                    duration: animationImages.length * 0.1,
                    repeat: currentAnimation === 'looping' ? Infinity : 0,
                    }}
                />
            </div>
        )
    }
}

export default OpenBox