import React from "react";
import NavButton from "./components/NavButton";
import ProgressBar from "./components/ProgressBar";
import EnergyStats from "./components/EnergyStats";
import MainBox from "./components/MainBox";
import LeagueInfo from "./components/LeagueInfo";
import Leaderboard from "./components/Leaderboard";
import OpenBox from "./components/OpenBox";
import LanguageSelector from "./components/LanguageSelector";
import SwitchButton from "./components/SwitchButton";
import Task from "./components/Task";
import Profile from "./components/Profile";
import Resourse from "./Resourse";
import Network from "./Networking";

const res = Resourse;
const net = Network;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //scenes
            activeButton: "boxes",
            gameContent: "boxes",
            globalContent: "game",
            //OHD
            player:{
                boxes: 0,
                maxEnegry: 7000,
                correntEnergy: 0,
                avatar: 0,
                league: 0,
                coins:60,
                powerpoints:60
            },
            //Adaptive
            energyHeight: 0
        };

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.changeGameContent = this.changeGameContent.bind(this);
        this.navRef = React.createRef();
        this.progressBoxRef = React.createRef();
    }

    componentDidMount() {
        this.updateOHD();
        this.updateEnergyHeight();
        window.addEventListener('resize', this.updateEnergyHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateEnergyHeight);
    }

    updateEnergyHeight = () => {
        if (this.progressBoxRef.current && this.navRef.current) {
            let lead_temp = this.progressBoxRef.current.getBoundingClientRect();
            let nav_temp = this.navRef.current.getBoundingClientRect();

            const enegry_Height =( nav_temp.top - lead_temp.bottom) - ( nav_temp.top - lead_temp.bottom) + 5;
            this.setState({ energyHeight: enegry_Height});
        }
    };

    async handleButtonClick(id) {
        this.setState({ activeButton: id });
        this.changeGameContent(id)
    }

    changeGameContent(content) {
        this.setState({ gameContent: content });
    }

    updatePlayer = (value) => {
        this.setState((prevState) => ({
          player: {
            ...prevState.player,
            ...value
          }
        }));
      };

    async updateOHD() {
        try {
            const resp = await net.request(net.E.OHD, net.M.GET, {}, {}, () => {
                // no response message
            }, 10000);

            this.setState({
                player: {
                    ...this.state.player,
                    boxes: resp.json.boxes,
                    avatar: resp.json.avatar,
                    maxEnegry: resp.json.maxEnegry,
                    correntEnergy: resp.json.correntEnergy,
                    league: resp.json.league
                }
            });
        } catch (error) {
            console.error("Failed to update OHD", error);
        }
    }

    render() {
        const { activeButton, gameContent, globalContent } = this.state;

        return (
            <div className='main'>
                {globalContent === "game" && (
                    <>
                    <div className='game'>
                    {gameContent === "boxes" && (
                        <>
                        <div className="league-box">
                            <LeagueInfo 
                                box={res.Icons.Load("box")}
                                league={this.state.player.league}
                                boxes={this.state.player.boxes}
                                onClick={() => this.handleButtonClick("leader")}
                            />
                        </div>
                        <MainBox />
                        <div className="progress-box" ref={this.progressBoxRef}>
                            <EnergyStats maxEng={this.state.player.maxEnegry} corrEng={this.state.player.correntEnergy}/>
                            <ProgressBar
                                bgcolor="#80DDFF"
                                max={this.state.player.maxEnegry}
                                corr={this.state.player.correntEnergy}
                                height={15}
                                width='100%'
                                margin={15}
                            />
                        </div>
                        </>
                    )}
                    {gameContent === "other" && (
                        <div className="settings-box">
                            <h1 id="other_label">SETTINGS</h1>
                            <LanguageSelector/>
                        </div>
                    )}
                    {gameContent === "task" && (
                        <Task nav={this.navRef}/>
                    )}
                    {gameContent === "shop" && (
                        <div>Shop Content</div>
                    )}
                    {gameContent === "profile" && (
                        <Profile player={this.state.player} updPlayer={this.updatePlayer} nav={this.navRef} />
                    )}
                    {gameContent === "leader" && (
                        <Leaderboard league={this.state.player.league} nav={this.navRef}/>
                    )}
                </div>
                <div className="nav" ref={this.navRef}>
                    <NavButton
                        src={res.Icons.Load("other")}
                        text="OTHER"
                        actv={activeButton === "other" ? "nav-button-activate" : "nav-button"}
                        id="other"
                        onClick={() => this.handleButtonClick("other")}
                    />
                    <NavButton
                        src={res.Icons.Load("task")}
                        text="TASK"
                        actv={activeButton === "task" ? "nav-button-activate" : "nav-button"}
                        id="task"
                        onClick={() => this.handleButtonClick("task")}
                    />
                    <NavButton
                        src={res.Icons.Load("box")}
                        text="BOXES"
                        actv={activeButton === "boxes" ? "nav-button-activate" : "nav-button"}
                        id="boxes"
                        onClick={() => this.handleButtonClick("boxes")}
                        ref={this.navRef}
                    />
                    <NavButton
                        src={res.Icons.Load("shop")}
                        text="SHOP"
                        actv={activeButton === "shop" ? "nav-button-activate" : "nav-button"}
                        id="shop"
                        onClick={() => this.handleButtonClick("shop")}
                    />
                    <NavButton
                        src={res.Avatars.Load(this.state.player.avatar)}
                        text="PROFILE"
                        actv={activeButton === "profile" ? "nav-button-activate" : "nav-button"}
                        id="profile"
                        onClick={() => this.handleButtonClick("profile")}
                    />
                </div>
                </>
            )}
            {globalContent === "openBox" && (
                    <OpenBox/>
            )}
                
            </div>
        );
    }
}

export default App;
