import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import Platform from './Protection/Platform';
import NoResponse from './Protection/NoResponse';
import Network from './Networking';
import ('./styles/main.css')

const app = ReactDOM.createRoot(document.getElementById('app'));
const net = Network;


window.Telegram.WebApp.SettingsButton.show()
window.Telegram.WebApp.SettingsButton.onClick(()=>{
    localStorage.clear();
})

const platform = window.Telegram.WebApp.platform;
var enablePlatform = ["android", "android_x", "ios", "web"];

var token = localStorage.getItem('token');
if (token) {
    var tokenExpireIn = localStorage.getItem('tokenExpireIn');
    var expireTime = new Date(tokenExpireIn);
    let now = new Date();
    var nowFormat = new Date(now.getTime() + (1 - now.getTimezoneOffset() / 60) * 3600 * 1000);

    if (nowFormat > expireTime) {
        (async () => {
            try {
                var resp = await net.request(net.E.AUTH_REFRESH, net.M.POST, {
                }, {
                    Authorization : localStorage.getItem('refreshToken')
                }, () => {
                    app.render(<NoResponse />);
                }, 1000);
    
                if (resp.code == 200){
                    localStorage.setItem('token',resp.json.token)
                    localStorage.setItem('refreshToken',resp.json.refreshToken)
                    localStorage.setItem('tokenExpireIn',resp.json.expireIn)
                    Run()
                }else{
                    app.render(<NoResponse />);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    } else {
        Run()
    }
} else {
    (async () => {
        try {
            var resp = await net.request(net.E.AUTH_TG, net.M.POST, {
                initData: window.Telegram.WebApp.initData,
                initDataUnsafe: window.Telegram.WebApp.initDataUnsafe
            }, {}, () => {
                app.render(<NoResponse />);
            }, 1000);

            if (resp.code == 200){
                localStorage.setItem('token',resp.json.token)
                localStorage.setItem('refreshToken',resp.json.refreshToken)
                localStorage.setItem('tokenExpireIn',resp.json.expireIn)
                Run()
            }else{
                app.render(<NoResponse />);
            }
        } catch (error) {
            console.error(error);
        }
    })();
}
function Run(){
    if (enablePlatform.indexOf(platform) != -1) {
        app.render(<App />);
    } else {
        app.render(<Platform />);
    }
}