import React from "react";
import Resourse from "../Resourse";

const res = Resourse;


class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'profile',
            avatars:[
                {
                    id: 0,
                    available: true
                },
                {
                    id: 12,
                    available: false
                },
                {
                    id: 31,
                    available: true
                }
            ],
            brawlers:[
                {
                    id:0,
                    unlock:true,
                    lvl:10,
                    gadget: false,
                    starpower: true
                },
                {
                    id:0,
                    unlock:true,
                    lvl:11,
                    gadget: true,
                    starpower: true
                },
                {
                    id:0,
                    unlock:true,
                    lvl:6,
                    gadget: true,
                    starpower: true
                },
                {
                    id:0,
                    unlock:false,
                    lvl:6,
                    gadget: true,
                    starpower: true
                },
                {
                    id:0,
                    unlock:false,
                    lvl:6,
                    gadget: true,
                    starpower: true
                }
            ],
            ProfileHeight: 0,
        }

        this.ProfileRef = React.createRef();
    }

    componentDidMount() {
        this.updateTaskpDeskHeight();
        window.addEventListener('resize', this.updateTaskpDeskHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateTaskpDeskHeight);
    }

    updateTaskpDeskHeight = () => {
        if (this.ProfileRef.current && this.props.nav.current) {
            let lead_temp = this.ProfileRef.current.getBoundingClientRect();
            let nav_temp = this.props.nav.current.getBoundingClientRect();

            const ProfileHeight = Math.abs((nav_temp.top - lead_temp.bottom) - 30);
            this.setState({ ProfileHeight });
        }
    };

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab }, () => {
            if (tab === 'brawlers' && this.state.ReferalDeskHeight === 0) {
                this.updateTaskpDeskHeight();
            }
        });
    }

    setAvatar = (id, available) => {
        if (available){
            this.props.updPlayer({avatar: id})
            this.setActiveTab('profile')
        }
    }

    render(){
        const { activeTab } = this.state;

        const Avatars =[]

        const Brawlers = []
        const LockBrawlers = []

        for (let ava of this.state.avatars){
            Avatars.push(
                <div className={`profile-avatar-pic ${ava.available ? '' : 'disable'}`} style={{backgroundImage:`url(${res.Avatars.Load(ava.id)})`}} onClick={() => this.setAvatar(ava.id,ava.available)}></div>
            )
        }

        for (let brawler of this.state.brawlers){
            if (brawler.unlock){
                Brawlers.push(
                    <div className="brawler">
                        <div className="brawler-icon" style={{backgroundImage:`url(${res.Brawlers.Load(0).icon})`, backgroundColor:res.Brawlers.Load(0).backround}}>
                            <h2>{res.Brawlers.Load(0).tmpName}</h2>
                            <div className="brawler-items">
                                <div className="brawler-items-gadget" style={{backgroundImage:`url(${brawler.lvl >= 7 ? (brawler.gadget ? res.Icons.Load("gadget") : res.Icons.Load("no-gadget")) : res.Icons.Load("no-7lvl")})`}}></div>
                                    <div className="brawler-items-starpower" style={{backgroundImage:`url(${brawler.lvl >= 9 ? (brawler.gadget ? res.Icons.Load("starpower") : res.Icons.Load("no-starpower")) : res.Icons.Load("no-9lvl")})`}}></div>
                                </div>
                            </div>
                            <div className="brawler-upgrade" style={{backgroundColor: `${brawler.lvl === 11 ? "#FFCE19" : (this.props.player.coins >= res.Levels.Load(brawler.lvl + 1).coins && this.props.player.powerpoints >= res.Levels.Load(brawler.lvl + 1).powerpoints ? "#34E820" : "#382739")}`, borderTop:`${brawler.lvl === 11 ? "" : (this.props.player.coins >= res.Levels.Load(brawler.lvl + 1).coins && this.props.player.powerpoints >= res.Levels.Load(brawler.lvl + 1).powerpoints ? "2px solid #223555" : "")}`,height:`${brawler.lvl === 11 ? "25%" : (this.props.player.coins >= res.Levels.Load(brawler.lvl + 1).coins && this.props.player.powerpoints >= res.Levels.Load(brawler.lvl + 1).powerpoints ? "24%" : "25%")}`}}>
                                <div className="brawler-upgrade-icon" style={{backgroundImage:`url(${brawler.lvl !== 11 ? (this.props.player.coins >= res.Levels.Load(brawler.lvl + 1).coins && this.props.player.powerpoints >= res.Levels.Load(brawler.lvl + 1).powerpoints ? res.Icons.Load("power-lvl-upgrade") : res.Icons.Load("power-lvl")) : res.Icons.Load("power-lvl")})`}}>
                                    <h3>{brawler.lvl}</h3>
                                </div>
                                {brawler.lvl !== 11 && (
                                    <>
                                    <div className="brawler-upgrade-pp" style={{backgroundImage:`url(${res.Icons.Load("powerpoint")})`, marginLeft:`5%`}}></div><h2 style={{color:`${this.props.player.coins >= res.Levels.Load(brawler.lvl + 1).coins ? "white" : "red"}`}}>{res.Levels.Load(brawler.lvl + 1).coins}</h2>
                                    <div className="brawler-upgrade-pp" style={{backgroundImage:`url(${res.Icons.Load("coin")})`, marginLeft:`5%`}}></div><h2 style={{color:`${this.props.player.powerpoints >= res.Levels.Load(brawler.lvl + 1).powerpoints ? "white" : "red"}`}}>{res.Levels.Load(brawler.lvl + 1).powerpoints}</h2>
                                    </>
                                )}
                                {brawler.lvl === 11 && (
                                    <h4>MAX POWER</h4>
                                )}
                            </div>
                    </div>
                )
            }else{
                LockBrawlers.push(
                    <div className="brawler-lock">
                        <div className="brawler-icon" style={{backgroundImage:`url(${res.Brawlers.Load(0).icon})`, backgroundColor:res.Brawlers.Load(0).backround}}>
                            <h2>{res.Brawlers.Load(0).tmpName}</h2>
                        </div>
                            <div className="brawler-upgrade" style={{backgroundColor: "#382739", height:"25%"}}>
                                    <h5>To unlock, open<br></br>THE BOXES!</h5>
                                    <div className="brawler-upgrade-icon lock" style={{backgroundImage: `url(${res.Icons.Load("no-brawler")})`}}></div>
                            </div>
                    </div>
                )
            }
        }

        return (
            <div className="profile-screen">
                <div className="choose-task-tab">
                    <div
                        className={`choose-tab ${['avatars', 'profile'].includes(activeTab) ? 'active' : ''}`}
                        onClick={() => this.setActiveTab('profile')}
                    >
                        <h1>PROFILE</h1>
                    </div>
                    <div
                        className={`choose-tab ${activeTab === 'brawlers' ? 'active' : ''}`}
                        onClick={() => this.setActiveTab('brawlers')}
                    >
                        <h1>BRAWLERS</h1>
                    </div>
                </div>
                <div className={`profile-scroll ${['avatars', 'brawlers'].includes(activeTab) ? 'active' : ''}`}  ref={this.ProfileRef} style={{ height: this.state.ProfileHeight }}>
                    {activeTab === "profile" && (
                        <>
                        <div className="profile-info">
                            <div className="profile-avatar settings" style={{backgroundImage:`url(${res.Icons.Load("other")})`}} onClick={() => this.setActiveTab('avatars')}></div>
                            <div className="profile-avatar" style={{backgroundImage:`url(${res.Avatars.Load(this.props.player.avatar)})`}} onClick={() => this.setActiveTab('avatars')}>
                            </div>
                            <div className="profile-balance">
                                    <div className="profile-name">
                                        <h2>BIG dick</h2>
                                    </div>
                                    <div className="profile-gems-decor">
                                        <div className="profile-gems">
                                            <h2>1200</h2>
                                            <div id="profile-gems-icon" style={{backgroundImage:`url(${res.Icons.Load("gem")})`}}></div>
                                        </div>
                                        <div id="profile-gems-starpower" style={{backgroundImage:`url(${res.Icons.Load("starpower")})`}}></div>
                                    </div>
                            </div>
                        </div>
                        <div className="profile-stats">
                            <div className="profile-stats-col">
                                <div className="profile-stat-box-decor">
                                    <div className="profile-stat-icon" style={{backgroundImage:`url(${res.Icons.Load("coins")})`}}></div>
                                    <div className="profile-stat-box">
                                        <div className="profile-stat-support">
                                            <h3>678919370</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-stat-box-decor">
                                    <div className="profile-stat-icon" style={{backgroundImage:`url(${res.Icons.Load("gems-hres")})`}}></div>
                                    <div className="profile-stat-box">
                                        <div className="profile-stat-support">
                                            <h3>69</h3>
                                        </div> 
                                    </div>
                                </div>
                                <div className="profile-stat-box-decor">
                                    <div className="profile-stat-icon" style={{backgroundImage:`url(${res.Icons.Load("brawlers")})`}}></div>
                                    <div className="profile-stat-box">
                                        <div className="profile-stat-support">
                                            <h3>3/80</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-stats-col">
                                <div className="profile-stat-box-decor">
                                    <div className="profile-stat-icon" style={{backgroundImage:`url(${res.Icons.Load("powerpoints")})`}}></div>
                                    <div className="profile-stat-box">
                                        <div className="profile-stat-support">
                                            <h3>7191</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-stat-box-decor">
                                    <div className="profile-stat-icon" style={{backgroundImage:`url(${res.Icons.Load("box-hres")})`}}></div>
                                    <div className="profile-stat-box">
                                        <div className="profile-stat-support">
                                            <h3>0</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-stat-box-decor">
                                    <div className="profile-stat-icon" style={{backgroundImage:`url(${res.Icons.Load("calendar")})`}}></div>
                                    <div className="profile-stat-box">
                                        <div className="profile-stat-support">
                                            <h3>72D.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    )}
                    {activeTab === "avatars" && (
                        <>
                            <div id="profile-avatars-back">
                                <div id="profile-avatars-back-btn" onClick={() => this.setActiveTab('profile')}>
                                    <h2>BACK</h2>
                                </div>
                            </div>
                            <div className="profile-avatars-pick">
                                {Avatars}{Avatars}{Avatars}{Avatars}{Avatars}
                            </div>
                        </>
                    )}
                    {activeTab === "brawlers" && (
                        <>
                            <div id="profile-avatars-back">
                                <div id="profile-avatars-back-btn" onClick={() => this.setActiveTab('profile')}>
                                    <h2>RARITY</h2>
                                </div>
                                <div id="profile-avatars-back-btn" onClick={() => this.setActiveTab('profile')}>
                                    <h2>NAME</h2>
                                </div>
                                <div id="profile-avatars-back-btn" onClick={() => this.setActiveTab('profile')}>
                                    <h2>LEVEL</h2>
                                </div>
                            </div>
                            <div className="profile-brawlers-pick">
                                {Brawlers}
                            </div>
                            {this.state.brawlers.some(brawler => !brawler.unlock) && (
                                <>
                                <h3 id="lock-brawlers-label">BRAWLERS TO BE UNLOСKED</h3>
                                <div style={{backgroundColor:`#223555`,width:`95%`,height:`6px`,margin:`0px auto 20px`}}></div>
                                <div className="profile-brawlers-pick">
                                    {LockBrawlers}
                                </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default Profile;