import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Resourse from "../Resourse";

const res = Resourse;

class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: [
                { label: "English", id: "en" },
                { label: "Русский", id: "ru" },
                { label: "Українська", id: "ua" },
                { label: "Polski", id: "pl" },
                { label: "日本語", id: "jp" }
            ],
            activeLanguage: "English",
            isDropdownOpen: false
        };

        this.dropdownRef = React.createRef();
    }

    handleToggleDropdown = () => {
        this.setState(prevState => ({
            isDropdownOpen: !prevState.isDropdownOpen
        }));
    };

    handlePickLang = (lang) => {
        this.setState({
            isDropdownOpen: false,
            activeLanguage: lang.label
        });
    };

    render() {
        const variants = this.state.language.map(lang => (
            <motion.div
                className="dropdown-item"
                onClick={() => this.handlePickLang(lang)}
                key={lang.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.2 }}
            >
                <span id="lang-variant">{lang.label} ({lang.id})</span>
                <img src={res.Icons.Load("right")} className="dropdown-arrow"></img>
            </motion.div>
        ));

        return (
            <div className={`dropdown ${this.state.isDropdownOpen ? 'active' : ''}`}>
                <div className="dropdown-header" onClick={this.handleToggleDropdown}>
                    <div>
                        <span className="dropdown-title">Change language</span>
                        <span className="dropdown-selected">{this.state.activeLanguage}</span>
                    </div>
                    <img src={this.state.isDropdownOpen ? res.Icons.Load("down") : res.Icons.Load("right") } className="dropdown-arrow-main"></img>
                </div>
                <AnimatePresence>
                    {this.state.isDropdownOpen && (
                        <motion.div
                            className="dropdown-list"
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: this.dropdownRef.current ? this.dropdownRef.current.scrollHeight : "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            ref={this.dropdownRef}
                        >
                            {variants}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        );
    }
}

export default LanguageSelector;
